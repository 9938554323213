import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import ApiServices from "../../GlobalFiles/ApiServices/ApiServices";
import { Link, useNavigate, useParams } from "react-router-dom";

const PatientDashboardAppointments = ({patientData}) => {
  const paramsData = useParams()
  const [AllAppointmentData, setAllAppointmentData] = useState([]);
  const [AppointmentFormsData, setAppointmentFormsData] = useState([]);
  const accessToken = sessionStorage.getItem("accessToken");
  const LoginPatientID = sessionStorage.getItem("LoginPatientID");
  const LoginUserType = sessionStorage.getItem("LoginUserType");

  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/patient/${paramsData.pid}/appointment`;

        if (LoginUserType === "Patient") {
          endpoint = `/apis/default/portal/patient/${LoginPatientID}/appointment`;
        }

        const appointmentDataResponse = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });
        if (appointmentDataResponse.data === "Patient does not have any appointment data.") {
          setAllAppointmentData([]);
        } else {
          // Set AllAppointmentData to the received data
          setAllAppointmentData(appointmentDataResponse.data || []);
        }
        // setAllAppointmentData(appointmentDataResponse.data || []);
      } catch (error) {
        console.error("Error fetching appointment data:", error);
      }
    };

    fetchData();
  }, [LoginPatientID, LoginUserType]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint = `/apis/default/api/getFormsPatient/${paramsData.pid}`;

        if (LoginUserType === "Patient") {
          endpoint = `/apis/default/portal/getFormsPatient/${LoginPatientID}`;
        }
        const pendingFormsResponse = await ApiServices.get(endpoint, {
          Authorization: `Bearer ${accessToken}`,
        });
        // setAppointmentFormsData(pendingFormsResponse.data || []);
        if (pendingFormsResponse.data === "Forms data not available for this patient.") {
          setAppointmentFormsData([]);
        } else {
          // Set AllAppointmentData to the received data
          setAppointmentFormsData(pendingFormsResponse.data || []);
        }
      } catch (error) {
        console.error("Error fetching forms data:", error);
      }
    };
    fetchData();
  }, [LoginPatientID, LoginUserType]);

  const [AppointmentcombinedData, setAppointmentcombinedData] = useState([]);
  const [openIndices, setOpenIndices] = useState([]);

console.log("AppointmentcombinedData",AppointmentcombinedData)
  useEffect(() => { 
    const combinedData = AllAppointmentData&&AllAppointmentData?.map((appointment) => {
      const matchingForms = AppointmentFormsData&&AppointmentFormsData?.filter(
        (form) => form.eid === appointment.pc_eid
      );
      return {
        pc_eid: appointment.pc_eid,
        appointmentData: appointment,
        formsData: matchingForms,
      };
    });

    combinedData&&combinedData?.sort(
      (a, b) =>
        new Date(b.appointmentData.pc_eventDate) -
        new Date(a.appointmentData.pc_eventDate)
    );

    setAppointmentcombinedData(combinedData);
  }, [AllAppointmentData, AppointmentFormsData]);
  

  const toggleCollapse = (index) => {
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      setOpenIndices([...openIndices, index]);
    }
  };

  const navigate =useNavigate()

  const handleFormData = (formData) => {
    LoginUserType !== "Patient" ?
    navigate(`/admin/service-form/${formData.eid}/${formData.pid}/${formData.form_id}/${paramsData.uuid}`)
    :
    navigate(`/patient-dashboard/service-form/${formData.eid}/${formData.pid}/${formData.form_id}`)
  };

  return (
    <div className="table-responsive">
    {AppointmentcombinedData &&
      <Table striped bordered hover>
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Date</th>
            <th>Time</th>
            <th>Reason</th>
            <th>Status</th>
            <th>Bill</th>
          </tr>
        </thead>
        <tbody>
          {AppointmentcombinedData &&
            AppointmentcombinedData.map((appointment, index) => (
              <React.Fragment key={appointment.pc_eid}>
                <tr>
                  <td
                    onClick={() => toggleCollapse(index)}
                    style={{
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                  >
                    {openIndices.includes(index) ? "-" : "+"}
                  </td>
                  {appointment.appointmentData && (
                    <>
                      <td>{appointment.appointmentData.pc_eid}</td>
                      <td>{appointment.appointmentData.pc_eventDate}</td>
                      <td>{appointment.appointmentData.pc_startTime}</td>
                      <td>{appointment.appointmentData.appointment_type}</td>
                      <td style={{ textAlign: "center" }}>
                        {appointment.appointmentData.pc_apptstatus ===
                        "APPR" ? (
                          <Button variant="success">Approved</Button>
                        ) : (
                          <Button variant="warning">Pending</Button>
                        )}
                      </td>
                      <td>
                        {/* {appointment.appointmentData.verified_receptionist ===
                          1 &&  */}
                          <Link to={
                            LoginUserType !== "Patient" ?
                            `/admin/patient-details-view/${patientData.uuid}/${appointment.appointmentData.pc_eid}/${patientData.pid}`
                           : `/patient-dashboard/show-bill/${patientData.uuid}/${appointment.appointmentData.pc_eid}/${patientData.pid}`
                            }>
                          <Button variant="info">Show</Button>
                          </Link>
                          {/* } */}
                      </td>
                    </>
                  )}
                </tr>
                {(openIndices.includes(index) ||
                  appointment.formsData.some(
                    (formData) => formData.is_consent_provider_reviewed === 0
                  )) && (
                  <tr>
                    <td></td>
                    <td colSpan={6}>
                      <Table bordered>
                        <thead>
                          <tr>
                            <th>Service</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {appointment.formsData &&
                            appointment.formsData.map((formData, formIndex) => (
                              <tr key={formIndex}>
                                <td>{formData.service}</td>
                                <td>
                                  {formData.is_consent_provider_reviewed ==
                                  0 ? (
                                    <Button variant="danger" onClick={() => handleFormData(formData)}>Pending</Button>
                                  ) : (
                                    <Button variant="success" onClick={() => handleFormData(formData)}>Verified</Button>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
        </tbody>
      </Table>}
    </div>
  );
};

export default PatientDashboardAppointments;
