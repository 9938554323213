import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import AppointmentSlot from "./AppointmentSlot";
import { Col, Row } from "react-bootstrap";

const AppointmentCalendar = ({
  PatientToBookAppointment,
  handleModalClose,
  updateAppointmentData
}) => {
  const onSuccess = () => {
    handleModalClose();
  };
  const [selectedDate, setSelectedDate] = useState();
  console.log("selectedDate ::::",selectedDate)
  console.log("PatientToBookAppointment ::::",PatientToBookAppointment)
  const currentDate = new Date();
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
  // useEffect(() => {
  //   if (PatientToBookAppointment && PatientToBookAppointment.pc_eventDate) {
  //     const eventDateParts = PatientToBookAppointment.pc_eventDate.split('-');
  //     console.log("eventDateParts",eventDateParts)
  //     const eventDate = new Date(
  //       eventDateParts[0],
  //       eventDateParts[1] - 1,
  //       eventDateParts[2]
  //     );
  //     console.log("eventDate ::::))))",eventDate)
  //     setSelectedDate(eventDate);
  //   }
  // }, [PatientToBookAppointment]);
  


  

  const LoginUserType = sessionStorage.getItem("LoginUserType")


  const isWeekend = (date) => {
    const day = date.getDay();
    if (LoginUserType === "Patient") {
      return day === 0 || day === 1 || day === 2 || day === 3 ;
    } else {
      return false;
    }
  };

  const tileDisabled = ({ date }) => {
    return isWeekend(date);
  };

  // useEffect(() => {
  //   const findNextAvailableDate = () => {
  //     let nextDate = new Date();
  //     while (isWeekend(nextDate)) {
  //       nextDate.setDate(nextDate.getDate() + 1);
  //     }
  //     setSelectedDate(nextDate);
  //   };

  //   findNextAvailableDate();
  // }, []);

  useEffect(() => {
    const findNextAvailableDate = () => {
      let nextDate = new Date();
      while (isWeekend(nextDate)) {
        nextDate.setDate(nextDate.getDate() + 1);
      }
      setSelectedDate(nextDate);
    };
  
    if (PatientToBookAppointment && PatientToBookAppointment.pc_eventDate) {
      const eventDateParts = PatientToBookAppointment.pc_eventDate.split('-');
      const eventDate = new Date(
        eventDateParts[0],
        eventDateParts[1] - 1, // Adjust month since it's zero-based
        eventDateParts[2]
      );
      setSelectedDate(eventDate);
    } else {
      findNextAvailableDate();
    }
  }, [PatientToBookAppointment]);
  

  return (
    <div>
      <div className="appointment-calender-top-div">
        <h3
          className="section-one-head-tag"
          style={{
            fontSize: "22px",
            margin: "0px",
            paddingTop: "10px",
            paddingBottom: "10px",
            textAlign: "center",
            width: "100%",
          }}
        >
          Available Appointments Calendar
        </h3>
      </div>
      {PatientToBookAppointment ? (
        <Row>
          <Col sm={6}>
            <Calendar
              onChange={handleDateChange}
              value={selectedDate}
              minDate={currentDate}
              tileDisabled={tileDisabled}
            />
          </Col>
          <Col sm={6}>
            <AppointmentSlot
              selectedDate={selectedDate}
              PatientToBookAppointment={PatientToBookAppointment}
              onSuccess={onSuccess}
              updateAppointmentData={updateAppointmentData}
            />
          </Col>
        </Row>
      ) : (
        <div>
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            minDate={currentDate}
            tileDisabled={tileDisabled}
          />
          <AppointmentSlot
            selectedDate={selectedDate}
            PatientToBookAppointment={PatientToBookAppointment}
            updateAppointmentData={updateAppointmentData}
            // onSuccess={onSuccess}
          />
        </div>
      )}
    </div>
  );
};

export default AppointmentCalendar;
